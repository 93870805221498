<template>
  <div class="project-members tw-border tw-rounded-xl tw-p-5 board-scroll tw-overflow-y-auto tw-h-full">
    <h5>{{ $t('Members') }}</h5>

    <div v-if="project.members.length !== 0">
      <div
        v-for="(data, index) in project.members"
        :key="index"
        :class="index === 0 ? 'mt-2' : 'mt-1'"
        class="d-flex justify-content-start align-items-center"
      >
        <b-avatar
          v-if="data.avatar !== ''"
          :src="data.avatar"
          :style="{
            background: `#94a3b8`,
          }"
          class="mr-50"
          size="33"
        />
        <b-avatar
          v-if="data.avatar === ''"
          :style="{
            background: `#94a3b8`,
          }"
          :text="formatFullName(data.title)"
          class="mr-50"
          size="33"
          variant="primary"
        />
        <div class="user-page-info">
          <h6 class="mb-0">
            {{ data.title }}
          </h6>
          <small class="text-muted">{{ data.worker_type }}</small>
        </div>
        <b-button
          v-if="data.id !== mainUser.id && mainUser.id === project.creator.id"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="btn-icon ml-auto"
          size="sm"
          variant="danger"
          @click="deleteMember(data)"
        >
          <feather-icon icon="UserMinusIcon" />
        </b-button>
        <b-button
          v-if="data.id === project.creator.id"
          v-b-tooltip.hover.v-warning
          class="btn-icon ml-auto"
          size="sm"
          title="Creator"
          variant="outline-warning"
        >
          <feather-icon icon="StarIcon" />
        </b-button>
      </div>
    </div>
    <app-collapse v-if="newMembers.length !== 0" class="mt-1">
      <app-collapse-item title="Add members" class="mt-1 tw-border-0 tw-bg-[#fff] tw-shadow-none">
        <div
          v-for="(item, i) in newMembers"
          :key="i"
          :class="i === 0 ? 'mt-1' : 'mt-1'"
          class="d-flex align-items-center"
        >
          <b-avatar
            v-if="item.avatar !== ''"
            :src="item.avatar"
            :style="{
              background: `#94a3b8`,
            }"
            class="mr-50"
            size="33"
          />
          <b-avatar
            v-if="item.avatar === ''"
            :style="{
              background: `#94a3b8`,
            }"
            :text="formatFullName(item.title)"
            class="mr-50"
            size="33"
          />
          <div class="user-page-info">
            <h6 class="mb-0">
              {{ item.title }}
            </h6>
            <small class="text-muted">{{ item.worker_type }}</small>
          </div>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="btn-icon ml-auto"
            size="sm"
            variant="primary"
            @click="addMember(item)"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {BAvatar, BButton, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import ProjectsService from '@/services/projects.service'

export default {
  components: {
    BAvatar,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  props: ['project'],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    newMembers() {
      let users = this.$store.state.osBoard.users
      let usersArr = this.project.members.map(el => el.id)
      let newMembers = []
      for (const key in users) {
        if (!usersArr.includes(users[key].id)) newMembers.push(users[key])
      }
      return newMembers
    },
    mainUser() {
      return this.$store.state.osBoard.user
    },
  },
  methods: {
    formatFullName(name) {
      return name.split(' ')[0][0] + name.split(' ')[1][0]
    },
    deleteMember(member) {
      ProjectsService.deleteMember(this.project.id, [{id: member.id}]).then(() => {
        ProjectsService.getProject(this.project.id).then(r => {
          this.$store.commit('osBoard/SET_PROJECT', r.data)
        })
      })
    },
    addMember(member) {
      ProjectsService.addMembers(this.project.id, [{id: member.id}]).then(() => {
        ProjectsService.getProject(this.project.id).then(r => {
          this.$store.commit('osBoard/SET_PROJECT', r.data)
        })
      })
    },
  },
}
</script>

<style lang="scss">
.project-members .collapse-default .card-header,
.project-members .collapse-default .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.project-members .collapse-icon [data-toggle='collapse']:after {
  margin-top: -1px;
  left: 8.8rem;
}
</style>