<template>
  <div class="project tw-overflow-hidden tw-h-full tw-flex tw-flex-col">
    <os-loader v-if="isLoadingProject" class="mt-2" />
    <template v-else>
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-body class="d-flex flex-column justify-center">
              <div class="d-flex">
                <b-img v-if="storeProject.image" :src="storeProject.image" alt="" class="project-image" />
                <div v-else class="project-image">
                  <feather-icon icon="ImageIcon" size="40" />
                </div>
                <div>
                  <h3 class="tw-text-gray-700 tw-leading-[1.2] tw-font-semibold tw-mb-0">{{ storeProject.title }}</h3>
                  <p class="font-small-4 mb-0 text-secondary">{{ storeProject.description }}</p>
                </div>
              </div>
              <div class="mt-1 d-flex justify-content-between align-content-end">
                <div class="pl-1 border-left-primary border-left-3">
                  <p class="m-0 font-small-3 text-muted font-weight-bolder">
                    {{ $t('Created') }}: {{ formatDate(storeProject.created_at) }}
                  </p>
                  <p class="m-0 font-small-3 text-muted font-weight-bolder">
                    {{ $t('Author') }}: {{ storeProject.creator.title }}
                  </p>
                </div>
                <div class="d-flex align-items-end">
                  <b-nav class="nav-project">
                    <b-nav-item @click="createNews.status = true"> Add news</b-nav-item>
                    <b-nav-item :to="{name: 'tasks'}">Tasks</b-nav-item>
                    <b-nav-item :to="{name: 'board', query: {id: this.$route.query.id}}">Board</b-nav-item>
                    <b-nav-item :to="{name: 'api-service'}">API Doc</b-nav-item>
                  </b-nav>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <div class="tw-overflow-hidden tw-flex tw-gap-8">
        <div class="tw-w-1/4">
          <div class="tw-ui-card tw-border tw-rounded-xl tw-p-5">
            <ProjectTimeline :timeline="timeline" />
          </div>
        </div>
        <div class="tw-w-2/4">
          <b-card v-if="createNews.status">
            <b-card-title>Add news</b-card-title>
            <b-form-input v-model="createNews.title" placeholder="Title"></b-form-input>
            <b-form-input v-model="createNews.description" class="mt-1" placeholder="Description"></b-form-input>
            <div class="d-flex mt-1">
              <b-button class="mr-75 rounded-nrm" variant="primary" @click="addNews">Add</b-button>
              <b-button class="rounded-nrm" variant="outline-secondary" @click="createNews.status = false"
                >Cancel
              </b-button>
            </div>
          </b-card>
          <b-card v-for="(item, index) in newsReverse" :key="index">
            <h4>{{ item.title }}</h4>
            <b-card-sub-title class="tw-mt-2">{{ item.description }}</b-card-sub-title>
          </b-card>
        </div>
        <div class="overflow-hidden tw-w-1/4">
          <ProjectMembers :project="storeProject" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormInput,
  BImg,
  BNav,
  BNavItem,
  BRow,
} from 'bootstrap-vue';
import ProjectMembers from '@/views/ProjectMembers';
import ProjectTimeline from '@/views/ProjectTimeline';
import moment from 'moment';
import Ripple from 'vue-ripple-directive';
import ProjectsService from '@/services/projects.service';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BNav,
    BImg,
    BNavItem,
    ProjectMembers,
    ProjectTimeline,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoadingProject: false,
      createNews: {
        status: false,
        title: '',
      },
      timeline: [
        {
          title: 'Goal Achieved',
          subtitle: 'All milestones are completed',
          time: 'few minutes ago',
          variant: 'success',
        },
        {
          title: 'Last milestone remain',
          subtitle: 'You are just one step away from your goal',
          time: '3 minutes ago',
          variant: 'info',
        },
        {
          title: 'Your are running low on time',
          subtitle: 'Only 30 minutes left to finish milestone',
          time: '21 minutes ago',
          variant: 'warning',
        },
      ],
      news: [
        {
          title: 'Важная новость',
          description: 'Описание новости',
        },
        {
          title: 'Вторая важная новость',
          description: 'Описание второй новости',
        },
      ],
      project: {
        image: null,
        members: [],
        description: 'Only 30 minutes left to finish milestone',
      },
      members: [
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
          title: 'Peter Reed',
          role: 'Frontend',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
          title: 'Harriett Adkins',
          role: 'Design',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
          title: 'Juan Weaver',
          role: 'Backend',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
          title: 'Claudia Chandler',
          role: 'Frontend',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          title: 'Earl Briggs',
          role: 'Backend',
        },
        {
          // eslint-disable-next-line no-undef
          avatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
          title: 'Jonathan Lyons',
          role: 'Backend',
        },
      ],
    };
  },
  computed: {
    newsReverse() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.news.reverse();
    },
    storeProject() {
      return this.$store.state.osBoard.selectedProject;
    },
  },
  mounted() {
    this.isLoadingProject = true;
    ProjectsService.getProject(this.$route.query.id).then(r => {
      localStorage.setItem('selected_project', JSON.stringify(r.data));
      this.$store.commit('osBoard/SET_PROJECT', r.data);
      this.isLoadingProject = false;
    });
  },
  methods: {
    addNews() {
      this.news.push({
        title: this.createNews.title,
        description: this.createNews.description,
      });
      this.createNews.title = '';
      this.createNews.description = '';
      this.createNews.status = false;
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format(
        this.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'
      );
    },
    openBoard() {
      this.$router.push({name: 'board', query: {id: this.$route.query.id}});
    },
    openTasks() {
      this.$router.push({name: 'tasks'});
    },
  },
};
</script>

<style lang="scss">
.project {
  .project-image {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 60px;
    height: 60px;
    border-radius: 0.55rem;
    margin-right: 15px;
    background: #f5f8fa;

    svg {
      color: #d8e0e6;
    }
  }

  .nav-project .nav-link {
    padding: 0 0.5rem;
  }
}
</style>