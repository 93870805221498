var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-members tw-border tw-rounded-xl tw-p-5 board-scroll tw-overflow-y-auto tw-h-full"},[_c('h5',[_vm._v(_vm._s(_vm.$t('Members')))]),_vm._v(" "),(_vm.project.members.length !== 0)?_c('div',_vm._l((_vm.project.members),function(data,index){return _c('div',{key:index,staticClass:"d-flex justify-content-start align-items-center",class:index === 0 ? 'mt-2' : 'mt-1'},[(data.avatar !== '')?_c('b-avatar',{staticClass:"mr-50",style:({
          background: "#94a3b8",
        }),attrs:{"src":data.avatar,"size":"33"}}):_vm._e(),_vm._v(" "),(data.avatar === '')?_c('b-avatar',{staticClass:"mr-50",style:({
          background: "#94a3b8",
        }),attrs:{"text":_vm.formatFullName(data.title),"size":"33","variant":"primary"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"user-page-info"},[_c('h6',{staticClass:"mb-0"},[_vm._v("\n          "+_vm._s(data.title)+"\n        ")]),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.worker_type))])]),_vm._v(" "),(data.id !== _vm.mainUser.id && _vm.mainUser.id === _vm.project.creator.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon ml-auto",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteMember(data)}}},[_c('feather-icon',{attrs:{"icon":"UserMinusIcon"}})],1):_vm._e(),_vm._v(" "),(data.id === _vm.project.creator.id)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-warning",modifiers:{"hover":true,"v-warning":true}}],staticClass:"btn-icon ml-auto",attrs:{"size":"sm","title":"Creator","variant":"outline-warning"}},[_c('feather-icon',{attrs:{"icon":"StarIcon"}})],1):_vm._e()],1)}),0):_vm._e(),_vm._v(" "),(_vm.newMembers.length !== 0)?_c('app-collapse',{staticClass:"mt-1"},[_c('app-collapse-item',{staticClass:"mt-1 tw-border-0 tw-bg-[#fff] tw-shadow-none",attrs:{"title":"Add members"}},_vm._l((_vm.newMembers),function(item,i){return _c('div',{key:i,staticClass:"d-flex align-items-center",class:i === 0 ? 'mt-1' : 'mt-1'},[(item.avatar !== '')?_c('b-avatar',{staticClass:"mr-50",style:({
            background: "#94a3b8",
          }),attrs:{"src":item.avatar,"size":"33"}}):_vm._e(),_vm._v(" "),(item.avatar === '')?_c('b-avatar',{staticClass:"mr-50",style:({
            background: "#94a3b8",
          }),attrs:{"text":_vm.formatFullName(item.title),"size":"33"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"user-page-info"},[_c('h6',{staticClass:"mb-0"},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")]),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.worker_type))])]),_vm._v(" "),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon ml-auto",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addMember(item)}}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}})],1)],1)}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }