<template>
  <app-timeline>
    <app-timeline-item
      v-for="(item, index) in timeline"
      :key="index"
      :subtitle="item.subtitle"
      :time="item.time"
      :title="item.title"
      :variant="item.variant"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    timeline: Array,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
